import './App.css';
import {Chart} from 'react-google-charts'
import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField';
import {Button, Grid, Slider, styled, Typography} from "@mui/material";
import Divider from '@mui/material/Divider';
import {Container} from "@mui/material";
import {options} from "./chartConfig";
import {orange } from '@mui/material/colors'

function valuetext(value){
    return value;
}
function App() {
  const [data,setData] = useState(null);
  const [params,setParams] = useState({"salary":[80,300]});
  useEffect(()=>{
    callAPI()
  },[]);

  const callAPI = () => {
    const url = '/api/chart'
    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    }).then(res => res.json())
        .then(newData => {
          console.log(newData);
          setData(newData);
        });
  }
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setParams(values => ({...values, [name]: value}))
  }
  const handleSalaryChange = (event, newValue, activeThumb) => {
      console.log(newValue);
    if (Array.isArray(newValue)){
        setParams(values => ({...values, ["salary"]: newValue}));
    } else {
        let salary = [params["salary"][0],params["salary"][1]];
        salary[activeThumb] = newValue;
        setParams(values => ({...values, ["salary"]: salary}));
        console.log(params);
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(params);
    callAPI();
  }

  return (
    <div className="App">
      <header className="App-header" class="flex">
          <Box
              component="div"
              sx={{
                  '& > :not(style)': { m: 3, width: '95%' },
              }}
              noValidate
          >
          <div>
            Welcome to Rick's Organization! I decided to use a treemap instead of an org-chart library as I thought that organizing
            the employees by department and office was more interesting than showing the
            organization hierarchy of 4 managers and all of their employees managed.
              Feel free to click on a section to see the employees within that department/office,
              or use the search filters to filter through employees in the organization! Right click to "zoom out" of a section.

          </div>
          </Box>

      </header>
      <Divider/>
      <div class="topdiv">
          <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                      <Grid item xs='auto'>
                          <TextField
                              id="name"
                              name="name"
                              label="Name"
                              type="text"
                              variant="outlined"
                              value={params.name}
                              onChange={handleChange}
                          />
                      </Grid>
                      <Grid item xs='auto'>
                          <TextField
                              name="skill"
                              id="skill"
                              label="Skill"
                              type="text"
                              variant="outlined"
                              value={params.skill}
                              onChange={handleChange}
                          />
                      </Grid>
                      <Grid item xs='auto'>
                          <Box sx = {{width: 250}}>
                              <Typography >Salary</Typography>
                              <Slider
                                  color="warning"
                                  name="salary"
                                  id="salary"
                                  label="Salary"
                                  value={params.salary}
                                  onChange={handleSalaryChange}
                                  getAriaValueText={valuetext}
                                  min={80}
                                  max={300}
                                  disableSwap
                              />
                          </Box>
                      </Grid>
                      <Grid item xs='auto'>
                          <Button
                              variant="outlined"
                              type="submit"
                              size="large"
                          >
                              Search
                          </Button>
                      </Grid>
                  </Grid>
          </form>

      </div>
      <Chart
          chartType="TreeMap"
          data = {data}
          options = {options}
          width="100%"
          height="750px"
      />
    </div>
  );
}

export default App;
