export const options = {
    minColor: '#ffcc80',
    midColor: '#ff9800',
    maxColor: '#f57c00',
    fontColor: 'black',
    fontSize: 20,
    showScale: false,
    enableHighlight: true,
    minHighlightColor: '#8c6bb1',
    midHighlightColor: '#9ebcda',
    maxHighlightColor: '#edf8fb',

    headerHeight: 80,
    hintOpacity: 0.8,
    maxPostDepth: 1,

}